import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import styled from "styled-components"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import MultimediaText from "../components/multimediaText";
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const SobrePage = ({ data, location }) => (
  <>
    <SEO title="Sobre Nós" image={data.sobreJson.intro.background.childImageSharp.fluid.src} />
    <Layout>
      {useBreakpoint().mobile ? (
        <>
          <HeaderMobile data={data.globalJson.header} />

          <SliderSwiper data={[data.sobreJson.introMobile]} center top />

          <SobrePageStyled>
            <div className="containers-multimedia-text">
              {data.sobreJson.mobile.multimediaText.map((item, index) => (
                <div key={'multimedia-text-' + index} className="container-multimedia-text" style={{marginBottom: '-5vh'}}>
                  <MultimediaText data={item} inverse={false} video={false} justify />
                  {(index < (data.sobreJson.mobile.multimediaText.length - 1)) && <hr style={{marginBottom: '10vh', marginTop: '-5vh'}} />}
                </div>
              ))}
            </div>
          </SobrePageStyled>

          <FooterMobile data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
        </>
      ) : (
        <>
          <Header data={data.globalJson.header} />

          <SliderSwiper data={[data.sobreJson.intro]} center oneSlideBorder={false} top />

          <SobrePageStyled>
            <div className="containers-multimedia-text">
              {data.sobreJson.multimediaText.map((item, index) => (
                <div key={'multimedia-text-' + index} className="container-multimedia-text">
                  <MultimediaText data={item} inverse={index % 2 ? true : false} video={false} slide={false} />
                  {(index < (data.sobreJson.multimediaText.length - 1)) && <hr />}
                </div>
              ))}
            </div>
          </SobrePageStyled>

          <Footer data={data.globalJson.footer} floatButtons={data.globalJson.floatButtons} />
        </>
      )}
    </Layout>
  </>
)

export default SobrePage

export const Json = graphql`
  query sobre {
    sobreJson {
      intro {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      introMobile {
        background {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        description
      }
      multimediaText {
        btnClass
        btnLink
        description
        btnText
        id
        title
        video
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3400) {
              src
              srcWebp
            }
          }
        }
      }
      mobile{
        multimediaText {
          btnClass
          btnLink
          description
          btnText
          id
          title
          video
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3400) {
                src
                srcWebp
              }
            }
          }
        }
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
const SobrePageStyled = styled.div`.containers-multimedia-text{
  padding: 15vh 5vw 10vh 5vw;
}
hr{
  margin-top: 5vh;
  margin-bottom: 5vh;
  height: 1px;
  background-color: #5A5A5A;
  border: none;
} 

@media (max-width: 990px){
  padding-top: 0 !important;
  margin-top: -20vh;
}
`